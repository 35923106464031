<template>
  <div>
    <el-scrollbar>
      <div>
        <el-main>
          <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="170px"
                   class="demo-ruleForm form-container"
                   style="margin-top: 20px; width: 99%;">
            <el-row class="custom-row" :gutter="20">
              <el-col :span="12">
                <el-form-item label="企业名称" prop="companyName">
                  <template slot="label">
                    <span>企业名称</span>
                  </template>
                  <el-input
                    disabled="true"
                    v-model="dataForm.companyName"
                    controls-position="right"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="统计月份" prop="dateAndTime">
                  <el-date-picker
                    v-model="dataForm.dateAndTime"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    :disabled="type === 'view' || type != 'add'"
                    @change="chooseDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="custom-row" :gutter="20">
              <el-col :span="12">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属集团二级企业编码" prop="parentUnitCode">
                  <el-input
                    v-model="dataForm.parentUnitCode"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属集团二级企业名称" prop="parentCompanyName">
                  <el-input
                    v-model="dataForm.parentCompanyName"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故发生起数" prop="accCnt">
                  <el-input
                    v-model="dataForm.accCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.accCntS<0 || dataForm.accCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.accCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故死亡人数" prop="deathCnt">
                  <el-input
                    v-model="dataForm.deathCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.deathCntS<0 || dataForm.deathCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.deathCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故重伤人数" prop="seriousInjuryCnt">
                  <el-input
                    v-model="dataForm.seriousInjuryCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.seriousInjuryCntS<0 || dataForm.seriousInjuryCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.seriousInjuryCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故轻伤人数" prop="minorInjuryCnt">
                  <el-input
                    v-model="dataForm.minorInjuryCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.minorInjuryCntS<0 || dataForm.minorInjuryCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.minorInjuryCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="直接经济损失（万元）" prop="directEconomicLosses">
                  <el-input
                    v-model="dataForm.directEconomicLosses"
                    controls-position="right"
                    maxlength="10"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.directEconomicLossesS<0 || dataForm.directEconomicLossesS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.directEconomicLossesS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="间接经济损失（万元）" prop="indirectEconomicLosses">
                  <el-input
                    v-model="dataForm.indirectEconomicLosses"
                    controls-position="right"
                    maxlength="10"
                    show-word-limit
                    :readonly="type == 'view'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.indirectEconomicLossesS<0 || dataForm.indirectEconomicLossesS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.indirectEconomicLossesS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-main>
      </div>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button v-if="type!='view'" @click="headSave">保 存</el-button>
      <el-button v-if="type!='view'" type="primary" @click="headSubmit">提 交</el-button>
      <el-button v-if="type == 'view'" type="primary" @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, detailByBusiness, summaryClickDataState} from "@/api/report/safeAccidentStat";
import {detail as getProcessDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      rules: {
        dateAndTime: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        parentUnitCode: [
          {message: '所属集团二级企业编码为空', trigger: ['blur']},
        ],
        parentCompanyName: [
          {message: '所属集团二级企业名称为空', trigger: ['blur']},
        ],
        accCnt: [
          {required: true, message: '请输入事故发生起数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        deathCnt: [
          {required: true, message: '请输入事故死亡人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        seriousInjuryCnt: [
          {required: true, message: '请输入事故重伤人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        minorInjuryCnt: [
          {required: true, message: '请输入事故轻伤人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        directEconomicLosses: [
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位'},
        ],
        indirectEconomicLosses: [
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位'},
        ],
      },
      existingYearPeopleCnt: 0,
      existingYearOrgCnt: 0,
      type: 'view',
      dataForm: {
        dateSource: "fill",
        processInsId: ''
      },
      nodeId: '',
      formLoading: false,
      processTemplateKey: "report_fill_safe_accident_stat",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refresh() {
      return this.$store.state.common.report
    },
  },
  created() {
    // this.getProcessId();
    let {unitName, unitCode, type, id, nodeId} = this.rowQuery;
    this.type = type;
    this.nodeId = nodeId
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    if (['view', 'edit'].includes(this.type)) {
      if (id) {
        this.dataForm.id = id
        this.createData()
      }
    }
  },
  methods: {
    headCancel() {
      this.$emit('closeDialog');
    },
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    chooseDate(e) {
      this.dataForm.dateAndTime = e
      if (e != null) {
        detailByBusiness(this.dataForm).then((res) => {
          const data = res.data.data
          if (data.id && data.id != -1) {
            this.dataForm = data
          } else {
            this.dataForm.id = null
            this.dataForm.dataState = 1
            this.dataForm.processInsId = null
            this.dataForm.createName = null
            this.dataForm.updateName = null
          }
          if (data.existingYearPeopleCnt != -1) {
            this.existingYearPeopleCnt = data.existingYearPeopleCnt
          }
          if (data.existingYearOrgCnt != -1) {
            this.existingYearOrgCnt = data.existingYearOrgCnt
          }
        })
      }
    },
    initData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.dataState == -1) {
          item.dataState = 1
        }
        this.dataForm = item;
      })
    },
    createData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.dataState == 4) {
          this.dataForm = item
          this.dataForm.id = null;
          this.dataForm.dateAndTime = null;
          this.dataForm.accCnt = null
          this.dataForm.deathCnt = null
          this.dataForm.seriousInjuryCnt = null
          this.dataForm.minorInjuryCnt = null
          this.dataForm.directEconomicLosses = null
          this.dataForm.indirectEconomicLosses = null
          this.dataForm.accCntS = item.accCntS
          this.dataForm.deathCntS = item.deathCntS
          this.dataForm.seriousInjuryCntS = item.seriousInjuryCntS
          this.dataForm.minorInjuryCntS = item.minorInjuryCntS
          this.dataForm.directEconomicLossesS = item.directEconomicLossesS
          this.dataForm.indirectEconomicLossesS = item.indirectEconomicLossesS
          this.dataForm.processInsId = null
        } else {
          if (item.dataState == -1) {
            item.dataState = 1
          }
          this.dataForm = item;
        }
      })
    },
    headSave(cancel = false) {
      this.dataForm.dataSource = 'fill'
      if (!this.dataForm.id) {
        this.dataForm.dataState = 1
      }
      // if (this.dataForm.dataState == 1) {
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          this.$loading();
          saveOrUpdate(this.dataForm)
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data
              this.$message({
                type: "success",
                message: "操作成功,数据已刷新"
              });
            }).finally(() => {
            this.$loading().close();
          })
        }
      })
      // } else {
      //   this.$message.warning("请确定当前数据为草稿状态！");
      // }
    },
    headSubmit(row) {
      // if (this.dataForm.dataState == 1) {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.dataForm.dataSource = 'fill'
            saveOrUpdate(this.dataForm).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
              }
            }).then(() => {
              this.dataForm.templateCode = "safeAccidentStat"
              summaryClickDataState(this.dataForm).then(res => {
                if (res.data.code === 200) {
                  if (res.data.data) {
                    this.$confirm("下级还有数据未通过，是否继续提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    }).then(() => {
                      this.$confirm("确定将提交?", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                      })
                        .then(() => {
                          this.rpTaskAttributeSubmit(this.dataForm);
                        }).catch(() => {
                      })
                    });
                  } else {
                    this.$confirm("确定将提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        this.rpTaskAttributeSubmit(this.dataForm);
                      }).catch(() => {
                    })
                  }
                }
              })
            })
          }
        })
      // } else {
      //   this.$message.warning("当前数据已提交，请勿重复提交！")
      // }
    },
    rpTaskAttributeSubmit(data) {
      this.$loading();
      this.dataForm.dataState = 4;
      saveOrUpdate(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.$emit("closeDialog")
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.restRefresh();
          this.initData();
        }
      })
        .catch((err) => {
        })
        .finally(() => {
          this.$loading().close();
        });
      // this.formProcess.id = data.id;
      // this.formProcess.deptId = this.nodeId
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInsId = res.data.data;
      //   this.dataForm.processInsId = processInsId;
      //   this.dataForm.dataState = 2;
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       this.dataForm.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     saveOrUpdate(this.dataForm).then((res) => {
      //       if (res.data.code === 200) {
      //         this.$emit("closeDialog")
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //         this.restRefresh();
      //         this.initData();
      //       }
      //     })
      //       .catch((err) => {
      //       })
      //       .finally(() => {
      //         this.$loading().close();
      //       });
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

.form-container .el-form-item__content {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.form-container .el-input__inner {
  height: 40px; /* 调整输入框高度 */
  line-height: 40px; /* 调整行高以匹配输入框高度 */
}

.form-container .el-form-item {
  margin-bottom: 20px; /* 调整表单项之间的间距 */
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
