<template>
  <div>
    <el-scrollbar>
      <el-main>
        <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="180px"
                 class="demo-ruleForm form-container"
                 style="margin-top: 20px; width: 99%;">
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称" prop="companyName">
                <template slot="label">
                  <span>企业名称</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.companyName"
                  controls-position="right"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份" prop="statDate">
                <el-date-picker
                  v-model="dataForm.statDate"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :disabled="type === 'view' || type != 'add'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dataForm.updateTime"
                  type="date"
                  disabled="true"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="自查次数" prop="selfCheckCnt">
                <el-input
                  v-model="dataForm.selfCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.selfCheckCntS<0 || dataForm.selfCheckCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.selfCheckCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="互查次数" prop="mutualCheckCnt">
                <el-input
                  v-model="dataForm.mutualCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.mutualCheckCntS<0 || dataForm.mutualCheckCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.mutualCheckCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="危险化学品专项检查次数" prop="dangerousChemicalCheckCnt">
                <el-input
                  v-model="dataForm.dangerousChemicalCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.dangerousChemicalCheckCntS<0 || dataForm.dangerousChemicalCheckCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.dangerousChemicalCheckCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="矿山专项检查次数" prop="mineCheckCnt">
                <el-input
                  v-model="dataForm.mineCheckCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.mineCheckCntS<0 || dataForm.mineCheckCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.mineCheckCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级督查下级次数" prop="superiorCnt">
                <el-input
                  v-model="dataForm.superiorCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.superiorCntS<0 || dataForm.superiorCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.superiorCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="政府检查次数" prop="govCnt">
                <el-input
                  v-model="dataForm.govCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.govCntS<0 || dataForm.govCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.govCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患项数" prop="generalHDCnt">
                <el-input
                  v-model="dataForm.generalHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.generalHDCntS<0 || dataForm.generalHDCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.generalHDCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患整改数" prop="generalHDRestructuringCnt">
                <el-input
                  v-model="dataForm.generalHDRestructuringCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.generalHDRestructuringCntS<0 || dataForm.generalHDRestructuringCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.generalHDRestructuringCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患项数" prop="majorHDCnt">
                <el-input
                  v-model="dataForm.majorHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.majorHDCntS<0 || dataForm.majorHDCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.majorHDCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患整改数" prop="majorHDRestructuringCnt">
                <el-input
                  v-model="dataForm.majorHDRestructuringCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.majorHDRestructuringCntS<0 || dataForm.majorHDRestructuringCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.majorHDRestructuringCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般隐患整改率" prop="generalHDRectifiedRate">
                <el-input
                  v-model="generalHDRectifiedRate"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  append="%"
                  :disabled="true"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.generalHDRectifiedRateS==null||false?'上次填报数据：0':'上次填报数据：' + dataForm.generalHDRectifiedRateS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重大隐患整改率" prop="majorHDRectifiedRate">
                <el-input
                  v-model="majorHDRectifiedRate"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :disabled="true"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light"
                            :content="dataForm.majorHDRectifiedRateS==null||false?'上次填报数据：0':'上次填报数据：' + dataForm.majorHDRectifiedRateS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button v-if="type!='view'" @click="headSave">保 存</el-button>
      <el-button v-if="type!='view'" type="primary" @click="headSubmit">提 交</el-button>
      <el-button v-if="type == 'view'" type="primary" @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, saveSubmitInterface, summaryClickDataState} from "@/api/report/hazardInvestigation";
import {detail as getProcessDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        dateSource: "1",
      },
      nodeId: '',
      formLoading: false,
      processTemplateKey: "report_fill_hazard_investigation",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      rules: {
        statDate: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        selfCheckCnt: [
          {required: true, message: '请输入自查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        mutualCheckCnt: [
          {required: true, message: '请输入互查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        dangerousChemicalCheckCnt: [
          {required: true, message: '请输入危险化学品专项检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        mineCheckCnt: [
          {required: true, message: '请输入矿山专项检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        superiorCnt: [
          {required: true, message: '请输入上级督查下级次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        govCnt: [
          {required: true, message: '请输入政府检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDCnt: [
          {required: true, message: '请输入一般隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDRestructuringCnt: [
          {required: true, message: '请输入一般隐患整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDCnt: [
          {required: true, message: '请输入重大隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDRestructuringCnt: [
          {required: true, message: '请输入重大隐患整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        generalHDRectifiedRate: [
          {required: true, message: '请输入一般隐患整改率', trigger: ['blur']},
        ],
        majorHDRectifiedRate: [
          {required: true, message: '请输入重大隐患整改率', trigger: ['blur']},
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refresh() {
      return this.$store.state.common.report
    },
    generalHDRectifiedRate() {
      let generalHDCnt;
      let generalHDRestructuringCnt;
      let number = 0;
      generalHDCnt = (this.dataForm.generalHDCnt && (Number(this.dataForm.generalHDCnt).toString().replace(/\D/g, '')) || 0);
      generalHDRestructuringCnt = (this.dataForm.generalHDRestructuringCnt && (Number(this.dataForm.generalHDRestructuringCnt).toString().replace(/\D/g, '')) || 0);

      if (generalHDCnt > 0) {
        number = (generalHDRestructuringCnt / generalHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }
      if (generalHDCnt == 0) {
        number = 100;
      }
      if (Number(number) > 100) {
        this.dataForm.generalHDRectifiedRate = 100;
        return this.dataForm.generalHDRectifiedRate + "%"
      } else {
        this.dataForm.generalHDRectifiedRate = Number(number);
        return this.dataForm.generalHDRectifiedRate + "%"
      }
    },
    majorHDRectifiedRate() {
      let majorHDCnt;
      let majorHDRestructuringCnt;
      let number = 0;

      majorHDCnt = (this.dataForm.majorHDCnt && (Number(this.dataForm.majorHDCnt).toString().replace(/\D/g, '')) || 0);
      majorHDRestructuringCnt = (this.dataForm.majorHDRestructuringCnt && (Number(this.dataForm.majorHDRestructuringCnt).toString().replace(/\D/g, '')) || 0);
      if (majorHDCnt > 0) {
        number = (majorHDRestructuringCnt / majorHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }

      if (majorHDCnt == 0) {
        number = 100;
      }
      if (Number(number) > 100) {
        this.dataForm.majorHDRectifiedRate = 100;
        return this.dataForm.majorHDRectifiedRate + "%"
      } else {
        this.dataForm.majorHDRectifiedRate = Number(number);
        return this.dataForm.majorHDRectifiedRate + "%"
      }
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
            {
              label: this.$t('cip.cmn.btn.saveBtn'),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
            }
        );
        result.push(
            {
              label: "提交",
              emit: "head-submit",
              type: "button",
              icon: "",
              btnOptType: 'submit',
            }
        );
      }
      return result;
    },
  },
  created() {
    // this.getProcessId();
    let {unitName, unitCode, type, id, nodeId} = this.rowQuery;
    this.type = type;
    this.nodeId = nodeId
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    if (['view', 'edit'].includes(this.type)) {
      if (id) {
        this.dataForm.id = id
        this.createData()
      }
    }
  },
  methods: {
    headCancel() {
      this.$emit("closeDialog")
    },
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    generalHDRectifiedRate(value, type) {
      let generalHDCnt;
      let generalHDRestructuringCnt;
      let number = 0;

      generalHDCnt = (type === "generalHDCnt" ? value : this.dataForm.generalHDCnt) && (Number((type === "generalHDCnt" ? value : this.dataForm.generalHDCnt).toString().replace(/\D/g, '')) || 0);
      generalHDRestructuringCnt = (type === "generalHDCnt" ? this.dataForm.generalHDRestructuringCnt : value) && (Number((type === "generalHDCnt" ? this.dataForm.generalHDRestructuringCnt : value).toString().replace(/\D/g, '')) || 0);

      if (generalHDCnt > 0) {
        number = (generalHDRestructuringCnt / generalHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }

      if (Number(number) > 100) {
        this.dataForm.generalHDRectifiedRate = 100;
      } else {
        this.dataForm.generalHDRectifiedRate = Number(number);
      }
    },
    majorHDRectifiedRate(value, type) {
      let majorHDCnt;
      let majorHDRestructuringCnt;
      let number = 0;

      majorHDCnt = (type === "majorHDCnt" ? value : this.dataForm.majorHDCnt) && (Number((type === "majorHDCnt" ? value : this.dataForm.majorHDCnt).toString().replace(/\D/g, '')) || 0);
      majorHDRestructuringCnt = (type === "majorHDCnt" ? this.dataForm.majorHDRestructuringCnt : value) && (Number((type === "majorHDCnt" ? this.dataForm.majorHDRestructuringCnt : value).toString().replace(/\D/g, '')) || 0);

      if (majorHDCnt > 0) {
        number = (majorHDRestructuringCnt / majorHDCnt * 100).toFixed(0);
      } else {
        number = 0;
      }

      if (Number(number) > 100) {
        this.dataForm.majorHDRectifiedRate = 100;
      } else {
        this.dataForm.majorHDRectifiedRate = Number(number);
      }
    },
    initData() {
      detail(this.dataForm).then(res => {
        const item = res.data.data;
        this.dataForm = item;
      })
    },
    createData() {
      detail(this.dataForm).then(res => {
        const item = res.data.data;
        if (item.dataState == "4" && this.type == 'edit') {
          this.dataForm = item
          this.dataForm.id = null;
          this.dataForm.statDate = null;
          this.dataForm.selfCheckCnt = null
          this.dataForm.mutualCheckCnt = null
          this.dataForm.dangerousChemicalCheckCnt = null
          this.dataForm.mineCheckCnt = null
          this.dataForm.superiorCnt = null
          this.dataForm.govCnt = null
          this.dataForm.generalHDCnt = null
          this.dataForm.generalHDRestructuringCnt = null
          this.dataForm.majorHDCnt = null
          this.dataForm.majorHDRestructuringCnt = null
          this.dataForm.selfCheckCntS = item.selfCheckCntS
          this.dataForm.mutualCheckCntS = item.mutualCheckCntS
          this.dataForm.dangerousChemicalCheckCntS = item.dangerousChemicalCheckCntS
          this.dataForm.mineCheckCntS = item.mineCheckCntS
          this.dataForm.superiorCntS = item.superiorCntS
          this.dataForm.govCntS = item.govCntS
          this.dataForm.generalHDCntS = item.generalHDCntS
          this.dataForm.generalHDRestructuringCntS = item.generalHDRestructuringCntS
          this.dataForm.majorHDCntS = item.majorHDCntS
          this.dataForm.majorHDRestructuringCntS = item.majorHDRestructuringCntS
          this.dataForm.generalHDRectifiedRateS = item.generalHDRectifiedRateS
          this.dataForm.majorHDRectifiedRateS = item.majorHDRectifiedRateS
          this.dataForm.processInsId = null
          this.dataForm.dataState = null
        } else {
          this.dataForm = item;
        }
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          if (this.dataForm.statDate) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            this.dataForm.processInsId = null
            if (!this.dataForm.dateSource) {
              this.dataForm.dateSource = "1"
            }
            saveOrUpdate(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.warning(res.data.data);
              }
            })
                .finally(() => {
                  this.$loading().close();
                })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headSubmit(row) {
      if (this.dataForm.statDate) {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            saveOrUpdate(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.type = 'edit';
                let dataForm = this.dataForm
                dataForm.templateCode = "hazardInvestigation"
                summaryClickDataState(dataForm).then(res => {
                  if (res.data.code === 200) {
                    if (res.data.data) {
                      this.$confirm("下级还有数据未通过，是否继续提交?", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                      }).then(() => {
                        this.$confirm("确定将提交?", {
                          confirmButtonText: "确定",
                          cancelButtonText: "取消",
                          type: "warning"
                        })
                            .then(() => {
                              this.rpTaskAttributeSubmit(dataForm);
                            }).catch(() => {
                        })
                      });
                    } else {
                      this.$confirm("确定将提交?", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                      })
                          .then(() => {
                            this.rpTaskAttributeSubmit(dataForm);
                          }).catch(() => {
                      })
                    }
                  }
                })
              } else {
                this.$message.warning(res.data.data);
              }
            }).finally(() => {
              this.$loading().close();
            })
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.$loading();
      this.dataForm.dataState = "4";
      saveSubmitInterface(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.$emit("closeDialog")
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.restRefresh();
          this.initData();
        }
      }).catch((err) => {
      })
          .finally(() => {
            this.$loading().close();
          });
      // this.formProcess.id = data.id;
      // this.formProcess.deptId = this.nodeId
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInsId = res.data.data;
      //   this.dataForm.processInsId = processInsId;
      //   this.dataForm.dataState = "2";
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       this.dataForm.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     saveSubmitInterface(this.dataForm).then((res) => {
      //       if (res.data.code === 200) {
      //         this.$emit("closeDialog")
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //         this.restRefresh();
      //         this.initData();
      //       }
      //     })
      //       .catch((err) => {
      //       })
      //       .finally(() => {
      //         this.$loading().close();
      //       });
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
            (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
